import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/ui/notification.service';
import { XappOnboarderService } from 'src/app/services/xapp-onboarder/xapp-onboarder.service';

@Component({
  selector: 'rd-es-configuration',
  templateUrl: './es-configuration.component.html',
  styleUrls: ['./es-configuration.component.scss']
})
export class EsConfigurationComponent implements OnInit {
  @ViewChild('f') myNgForm;
  esConfigForm: FormGroup;
  responseMessage: string = '';

  constructor(private fb: FormBuilder, private xappOnboarderService: XappOnboarderService,
    private notificationService: NotificationService,
  ) {
    this.esConfigForm = this.fb.group({
      ueCountThresholdUnder: ['', Validators.required],
      throughputThresholdUnder: ['', Validators.required],
      ueCountThresholdOver: ['', Validators.required],
      throughputThresholdOver: ['', Validators.required],
      ueCountCell1: ['', Validators.required],
      ueCountCell2: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    console.log("es")
  }

  onSubmit() {
    if (this.esConfigForm.valid) {
      this.xappOnboarderService.onSubmitpostEsConfig(this.esConfigForm.value).subscribe(
        (response) => {
          this.responseMessage = 'Values successfully set!';
          this.notificationService.success("Your details have been successfully submitted.");
          this.myNgForm.resetForm();
        }
      )
    } else {
      this.notificationService.error('Something went wrong ');
      console.log('Form is invalid');
    }
  }

}
