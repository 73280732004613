import { Component, OnInit } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'rd-qo-s',
  templateUrl: './qo-s.component.html',
  styleUrls: ['./qo-s.component.scss']
})
export class QoSComponent implements OnInit {
  header: any;
  value: string = '';
  displayedColumns: string[] = ['gNodeB', 'cellId', 'ueId', 'status', 'gbr'];
  dataSource = new MatTableDataSource<any>([]);

  constructor(private xappOnboarderService: XappOnboarderService) { }

  ngOnInit(): void {
    this.getQosData();
  }

  cellData(value: any) {
    console.log(value, "cell value");
    this.value = '';
  }

  refreshTable() {
    this.getQosData();
  }

  convertBitsToDecimal(bits: string): number {
    return parseInt(bits, 2);
  }

  convertBitsToMB(bits: number): number {
    const megabits = bits / (1000 * 1000); // Convert bits to megabits (1 Mbps = 1,000,000 bits)
    return Math.trunc(megabits); // Truncate to return the integer part
  }

  extractFirstThreeCharacters(input: string): string {
    return input.slice(0, 3); // Extracts from index 0 to 3 (not inclusive of 3)
  }

  getQosData(): void {
    this.xappOnboarderService.getQosData().subscribe((res: any) => {
      this.dataSource.data = JSON.parse(res);
      let data = this.dataSource.data.map(x => {
        if (x.cellId) {
          const binaryString = x.cellId;
          const decimalNumber = this.convertBitsToDecimal(binaryString);
          x.cellId = decimalNumber;
        }
        if(x.ueId){
          const hexString = x.ueId;
          const firstThree = this.extractFirstThreeCharacters(hexString);
          x.ueId = firstThree; 
        }
        const createdDate = new Date(x.createdAt);
        const istDate = this.convertUTCtoIST(createdDate);
        const currentDate = new Date();
        const differenceInMs = currentDate.getTime() - istDate.getTime();    // Calculate the difference in milliseconds
        const differenceInMinutes = differenceInMs / (1000 * 60);    // Convert the difference to minutes
        if (differenceInMinutes > 15) {
          x.status = "Inactive";  // If more than 15 minutes, return "Inactive"
        }
        if (x.status === 0) {
          x.status = "Accepted"
        }
        if (x.status === 1) {
          x.status = "Rejected"
        }
        if (x.gbr) {
          const bits = x.gbr;
          const megabytes = this.convertBitsToMB(bits);
          x.gbr = megabytes;
        }
      })
    })
  }

  convertUTCtoIST(utcDateString: any): Date {
    const utcDate = new Date(utcDateString); // Convert the input string to a Date object
    const utcTime = utcDate.getTime(); // Get the UTC time in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30 (5.5 hours in milliseconds)
    const istTime = new Date(utcTime + istOffset); // Add the IST offset to the UTC time
    return istTime;
  }

}
