<div class="es-config-container">
  <form [formGroup]="esConfigForm" (ngSubmit)="onSubmit()" #f="ngForm">

    <!-- Under Utilized Cell Threshold -->
    <div class="form-section">
      <h3 class="labelClass">Under Utilized Cell Threshold</h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>UE Count Threshold</mat-label>
            <input matInput placeholder="Enter value" formControlName="ueCountThresholdUnder">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Throughput Threshold</mat-label>
            <input matInput placeholder="Enter value" formControlName="throughputThresholdUnder">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Over Utilized Cell Threshold -->
    <div class="form-section">
      <h3 class="labelClass">Over Utilized Cell Threshold</h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>UE Count Threshold</mat-label>
            <input matInput placeholder="Enter value" formControlName="ueCountThresholdOver">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Throughput Threshold</mat-label>
            <input matInput placeholder="Enter value" formControlName="throughputThresholdOver">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Enter Data to Simulate -->
    <div class="form-section">
      <h3 class="labelClass">Enter Data to Simulate</h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>UE Count for Cell 1</mat-label>
            <input matInput placeholder="Enter value" formControlName="ueCountCell1">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>UE Count for Cell 2</mat-label>
            <input matInput placeholder="Enter value" formControlName="ueCountCell2">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <button type="submit" [disabled]="!esConfigForm.valid">Submit</button>
  </form>
</div>