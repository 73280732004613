import { Component, OnInit } from '@angular/core';
import { AppConfigServiceService } from '../services/appconfig/app-config-service.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'rd-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  iFrame: any = null;

  constructor(private environment: AppConfigServiceService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.environment.getConfig().subscribe(response => {
      const url = JSON.parse(response)
      this.iFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url.logKibana);
    })
  }

}
