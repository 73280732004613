import { Component, OnInit } from '@angular/core';
import { AppConfigServiceService } from '../services/appconfig/app-config-service.service';
import {DomSanitizer} from "@angular/platform-browser";
@Component({
  selector: 'rd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  iFrame:any = null;

  constructor(private environment:AppConfigServiceService,private sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
    this.environment.getConfig().subscribe(response=>{
      const url = JSON.parse(response)
      this.iFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url.kibana);
    })
  }
}

