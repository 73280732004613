<!-- <div class="iframe_map">
    <iframe src="http://34.42.183.183:32369/d/d3865568-3ab8-47b9-a78f-cbd94217d09e/new-node-graph?orgId=1&viewPanel=1&from=1707802148959&to=1707823748959" width="100%" frameborder="0">

    </iframe>
</div> -->
<div class="app-control__section">
  <div class="iframe_map2">
    <iframe *ngIf="iFrame2" [src]="iFrame2" width="100%" sandbox></iframe>
  </div>
</div>
