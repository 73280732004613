/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { InstanceSelectorService } from 'src/app/services/instance-selector/instance-selector.service';
import { UiService } from '../../services/ui/ui.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';

@Component({
  selector: 'rd-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  isEnergySavingExpanded: boolean = false;

  header: string;
  myString: Observable<string>;
  headerTitle = "Dashboard";
  darkMode: boolean;
  @Output() sidenavClose: EventEmitter<any> = new EventEmitter();

  constructor(public ui: UiService, private instanceSelectorService: InstanceSelectorService, private route: ActivatedRoute,) { }
  public onClickdata(value: any) {
    if (value === "null" || value === null) {
      sessionStorage.setItem("value2", "Dashboard")
      this.instanceSelectorService.changeMessage("Dashboard")
    } else {
      sessionStorage.setItem("value2", value)
      this.instanceSelectorService.changeMessage(value)
      console.log("value", value)
    }

  }

  ngOnInit() {
    this.onClickdata(sessionStorage.getItem('value2'))
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  toggleEnergySaving() {
    this.isEnergySavingExpanded = !this.isEnergySavingExpanded;
  }

}
