<div class="catalog-container">
  <form class="row" [formGroup]="form" (ngSubmit)="startSimulator()">
    <table class="app-control-table width-100 tableRan">
      <tr>
        <th class="thRan">Feature</th>
        <th class="thRan">Enable e2 Sim</th>
      </tr>
      <tr *ngFor="let data of features">
        <td class="tdRan">{{ data.feature }}</td>
        <td class="tdRan">
          <div class="custom-radio">
            <label class="switch">
              <input type="checkbox" [formControlName]="data.feature" (change)="onToggleChange(data.feature, $event)">
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </tr>
    </table>
    <div class="form-actions">
      <button mat-raised-button color="primary" [disabled]="form.invalid">Submit</button>
    </div>
  </form>
</div>