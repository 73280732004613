<!-- my-component.html -->
<div class="head">
    <span class="text" *ngFor="let cell of parsedCellData">
        <span class="textLabel">{{ cell.cellName|uppercase }} :</span>
        <!-- Dynamically add 'online' or 'offline' class based on the status -->
        <span class="textValue" [ngClass]="cell.isOnline === 'Online' ? 'online' : 'offline'">
            {{ cell.isOnline }}
        </span>
    </span>
</div>

<div class="iframe_map">
    <iframe *ngIf="iFrame" [src]="iFrame" width="100%" height="100%" title="kibana"></iframe>
</div>