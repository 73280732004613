<div class="commonSection">
    <!-- [disabled]="!form.form.valid" -->

        <div class="qos-header">
            <div class="qos-header-left">
            <span class="label">Max_Bitrate per cell:</span>
            <mat-form-field class="example-form-field">
                <mat-label>Enter Value</mat-label>
                <input matInput type="text" [(ngModel)]="value">
                <button matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="cellData(value)">save</button>
        </div>
        <div>
            <button mat-mini-fab (click)="refreshTable()" >
                <span class="material-symbols-outlined"> refresh </span>
            </button>
        </div>
            
        </div>

    <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- gNodeB Column -->
            <ng-container matColumnDef="gNodeB">
                <th mat-header-cell *matHeaderCellDef> gNodeB </th>
                <td mat-cell *matCellDef="let element"> {{element.gnbId}} </td>
            </ng-container>

            <!-- CellId Column -->
            <ng-container matColumnDef="cellId">
                <th mat-header-cell *matHeaderCellDef> Cell-Id </th>
                <td mat-cell *matCellDef="let element"> {{element.cellId}} </td>
            </ng-container>

            <!-- UeId Column -->
            <ng-container matColumnDef="ueId">
                <th mat-header-cell *matHeaderCellDef > Ue-Id </th>
                <td mat-cell *matCellDef="let element"> {{element.ueId}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{
                    'connected': element.status === 'Accepted',
                    'Rejected': element.status === 'Rejected',
                    'Processing': element.status === 'Inactive'

                  }"> {{element.status}} </td>
            </ng-container>

            <!-- Gbr Column -->
            <ng-container matColumnDef="gbr">
                <th mat-header-cell *matHeaderCellDef> Gbr (Mbps) </th>
                <td mat-cell *matCellDef="let element"> {{element.gbr}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>